<script lang="ts" setup>
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import { ScreeningType } from '@factoryfixinc/ats-interfaces';
import ConfirmScreeningTypeChange from './ConfirmScreeningTypeChange.vue';
import TextCheckOptions from '@/components/Jobs/CopilotActivation/TextCheckOptions.vue';
import { computed, ref } from 'vue';
import ProjectService from '@/core/shared/project/project.service';

const activationService = new CopilotActivationService();
const projectService = new ProjectService();
const { STATIC, DYNAMIC } = ScreeningType;

const openScreeningTypeDialog = ref(false);
const hasShownScreeningTypeDialog = ref(false);

function selectScreeningType(newScreeningType: ScreeningType) {
  const copilotEnabled = projectService.currentProject?.copilot;

  if (
    copilotEnabled &&
    !hasShownScreeningTypeDialog.value &&
    newScreeningType === STATIC &&
    activationService.screeningType === DYNAMIC
  ) {
    openScreeningTypeDialog.value = true;
    hasShownScreeningTypeDialog.value = true;
    return;
  }
  setScreeningType(newScreeningType);
}

function setScreeningType(newScreeningType: ScreeningType) {
  activationService.screeningType = newScreeningType;
  openScreeningTypeDialog.value = false;
}

function cancelPendingScreeningAction() {
  openScreeningTypeDialog.value = false;
}
const isStaticSelected = computed<boolean>(() => activationService.screeningType === STATIC);
</script>

<template>
  <div>
    <div class="mb-4 text-lg font-extrabold">
      How would you like Copilot to screen candidates for this position?
    </div>
    <div class="flex flex-row gap-3">
      <TextCheckOptions :is-selected="isStaticSelected" @click="selectScreeningType(STATIC)"
        ><template v-slot:title>Classic</template>
        Robotically ask a list of preset questions, and add nothing further
      </TextCheckOptions>
      <TextCheckOptions
        :is-selected="!isStaticSelected"
        badge-content="BETA"
        @click="selectScreeningType(DYNAMIC)"
        ><template v-slot:title>Dynamic</template>
        Interview naturally—ask targeted questions and adapt as needed
      </TextCheckOptions>
    </div>
    <ConfirmScreeningTypeChange
      @save="setScreeningType(STATIC)"
      @cancel="cancelPendingScreeningAction"
      :is-open="openScreeningTypeDialog"
      :is-loading="false"
      v-if="openScreeningTypeDialog"
      confirm-action="Switch to preset questions"
    />
  </div>
</template>
