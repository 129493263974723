import { type RouteLocationNormalized, createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/layouts/default.vue';
import JobsLayout from '@/layouts/jobs.vue';
import SettingsLayout from '@/layouts/settings.vue';
import Sourcing from '@/views/sourcing.vue';
import Candidates from '@/views/candidates/index.vue';
import Jobs from '@/views/jobs/jobs.vue';
import JobsCopilotActivation from '@/views/jobs/copilot-activation.vue';
import Conversations from '@/views/conversations/conversations.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import SearchUtils from '@/utils/search-utils';
import MeService from '@/core/shared/me/me.service';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: DefaultLayout,
      children: [
        { path: '/', redirect: '/conversations' },
        {
          path: '/candidates',
          name: 'candidates',
          component: Candidates,
        },
        {
          path: '/',
          component: JobsLayout,
          children: [
            {
              path: '/sourcing',
              name: 'sourcing',
              component: Sourcing,
              children: [
                {
                  path: '',
                  name: 'sourcing-index',
                  component: () => import('@/views/sourcing/index.vue'),
                },
                {
                  path: '/sourcing/job/:id',
                  name: 'sourcing-job-id',
                  component: () => import('@/views/sourcing/project.vue'),
                },
                // #################################################################
                //
                // NOTE: Page temporarily disabled. Do not delete.
                //
                // #################################################################
                // {
                //   path: '/sourcing/tag/:id',
                //   name: 'sourcing-tag-id',
                //   component: () => import('@/views/sourcing/tag.vue'),
                // },
              ],
            },
            {
              path: 'conversations',
              name: 'conversations',
              component: Conversations,
              redirect: { name: 'resume-tab' },
              children: [
                {
                  path: 'conversation',
                  name: 'conversation-tab',
                  component: () => import('@/views/conversations/tabs/conversation-tab.vue'),
                },
                {
                  path: 'applications',
                  name: 'applications-tab',
                  component: () => import('@/views/conversations/tabs/applications-tab.vue'),
                },
                {
                  path: 'resume',
                  name: 'resume-tab',
                  component: () => import('@/views/conversations/tabs/resume-tab.vue'),
                },
                {
                  path: 'notes',
                  name: 'notes-tab',
                  component: () => import('@/views/conversations/tabs/notes-tab.vue'),
                },
              ],
            },
          ],
        },
        {
          path: '/settings',
          component: SettingsLayout,
          redirect: '/settings/company',
          children: [
            {
              path: 'company',
              name: 'settings-company',
              component: () => import('@/views/settings/company/company-overview.vue'),
            },
            {
              path: 'members',
              name: 'settings-members',
              component: () => import('@/views/settings/company/members/members.vue'),
            },
            {
              path: 'members/:id',
              name: 'settings-member-id',
              component: () => import('@/views/settings/company/members/member-profile.vue'),
              beforeEnter: (to, from, next) => {
                const meService = new MeService();
                if (meService.isEmployerAdmin) {
                  next();
                } else {
                  next({ name: 'settings-members' });
                }
              },
            },
            {
              path: 'profile',
              name: 'settings-profile',
              component: () => import('@/views/settings/account/account-profile.vue'),
            },
            {
              path: 'notifications',
              name: 'settings-notifications',
              component: () => import('@/views/settings/account/account-notifications.vue'),
            },
            {
              path: 'sign-in',
              name: 'settings-sign-in',
              component: () => import('@/views/settings/account/account-sign-in.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/jobs',
      component: Jobs,
      redirect: '/conversations', // Redirect to conversations for now
      children: [
        {
          path: '/jobs/:id/copilot-activation',
          name: 'jobs-copilot-activation',
          component: JobsCopilotActivation,
        },
      ],
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // }
  ],
});

router.beforeEach((routeLocation: RouteLocationNormalized) => {
  const trackingService = TrackingService.instance;
  const queryWithoutToken = {
    ...routeLocation.query,
    ...(routeLocation.query?.token ? { token: '_redacted_' } : {}),
  };
  trackingService.registerPageVisit({
    name: 'Page View',
    path: routeLocation.path,
    search: SearchUtils.convertSearchObjectToSearchUrl(queryWithoutToken),
    title: routeLocation.name?.toString() || '',
    url: `${location.origin}${routeLocation.fullPath}`,
  });
});

export default router;
