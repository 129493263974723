import {
  type Filter,
  HireType,
  type JobRelation,
  type JobWithRelations,
  PayRatePeriod,
  ScreeningAction,
  ScreeningType,
} from '@factoryfixinc/ats-interfaces';
import { useAtsJobApi, useFFRestApi } from '@/composables/useApi';
import { InternalError } from '@/core/shared/errors/internal.error';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import {
  type GenerateJobDescriptionOptions,
  type GenerateScreenerQuestionsOptions,
  type GeneratedJobDescriptionDocument,
  type GeneratedScreenerQuestionsDocument,
} from '@/core/shared/job/job.type';
import {
  formatJobDescription,
  getJobSkillsTextForJobDescription,
} from '@/core/jobs/copilot-activation/utils/html-parsing.utils';

export class JobPersistence {
  async getById<Relation extends JobRelation>(
    id: number,
    filter: Filter = {},
  ): Promise<JobWithRelations<Relation> | undefined> {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    const path = `/job/${id}?filter=${encodedFilter}`;
    const { data, error, statusCode } = await useFFRestApi(path).json<JobWithRelations<Relation>>();

    if (statusCode.value === HttpStatus.NOT_FOUND) {
      return undefined;
    }

    if (error.value) {
      throw new InternalError('Could not fetch job', {
        error: error.value,
        status: statusCode.value,
        data: { id },
      });
    }

    return data.value ?? undefined;
  }

  getByIdWithRelations<Relation extends JobRelation>(
    jobId: number,
    relations: Relation[],
  ): Promise<JobWithRelations<Relation> | undefined> {
    const filter: Filter = {
      include: [],
    };

    if (relations.includes('jobTitle' as Relation) && filter.include) {
      filter.include.push({ relation: 'jobTitle' });
    }

    if (relations.includes('employer' as Relation) && filter.include) {
      filter.include.push({ relation: 'employer' });
    }

    if (relations.includes('jobWatcherMaps' as Relation) && filter.include) {
      filter.include.push({ relation: 'jobWatcherMaps' });
    }

    if (relations.includes('employerUserJobWatcherMaps' as Relation) && filter.include) {
      filter.include.push({ relation: 'employerUserJobWatcherMaps' });
    }

    if (relations.includes('jobApplicants' as Relation) && filter.include) {
      filter.include.push({ relation: 'jobApplicants' });
    }

    if (relations.includes('screenerQuestions' as Relation) && filter.include) {
      filter.include.push({ relation: 'screenerQuestions' });
    }

    if (relations.includes('jobStatusHistories' as Relation) && filter.include) {
      filter.include.push({ relation: 'jobStatusHistories' });
    }

    if (relations.includes('jobQualifications' as Relation) && filter.include) {
      filter.include.push({ relation: 'jobQualifications' });
    }

    if (relations.includes('jobResponsibilities' as Relation) && filter.include) {
      filter.include.push({ relation: 'jobResponsibilities' });
    }

    if (relations.includes('jobTaxonomyMachineMaps' as Relation) && filter.include) {
      filter.include.push({
        relation: 'jobTaxonomyMachineMaps',
        scope: {
          include: [
            {
              relation: 'jobTaxonomyMachineMapTaxonomyBrandMaps',
            },
          ],
        },
      });
    }

    if (relations.includes('jobTaxonomyKnowledgeDisciplineMaps' as Relation) && filter.include) {
      filter.include.push({ relation: 'jobTaxonomyKnowledgeDisciplineMaps' });
    }

    return this.getById<Relation>(jobId, filter);
  }

  async generateJobDescription(
    options: GenerateJobDescriptionOptions,
  ): Promise<string | undefined> {
    const path = `/gpt-helper/generate-temporary-job-description`;
    const { data, error, statusCode } = await useAtsJobApi(path)
      .post({
        jobTitle: options.title,
        location: options.location,
        skills: options.skills,
        brands: options.brands,
      })
      .json<{ documentId: string }>();

    if (error.value) {
      throw new InternalError('Could not generate job description', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value?.documentId;
  }

  async getGeneratedJobDescription(
    documentId: string,
  ): Promise<GeneratedJobDescriptionDocument | null> {
    const path = `/gpt-helper/get-temporary-job-description/${documentId}`;
    const { data, error, statusCode } = await useAtsJobApi(path)
      .get()
      .json<GeneratedJobDescriptionDocument>();

    if (error.value) {
      throw new InternalError('Could not fetch generated job description', {
        error: error.value,
        status: statusCode.value,
        data: { documentId },
      });
    }

    return data.value;
  }

  async generateScreenerQuestions(
    options: GenerateScreenerQuestionsOptions,
  ): Promise<string | undefined> {
    const path = `/gpt-helper/generate-temporary-screening-questions`;
    const { data, error, statusCode } = await useAtsJobApi(path)
      .post({
        jobTitle: options.title,
        skills: options.skills,
        brands: options.brands,
      })
      .json<{ documentId: string }>();

    if (error.value) {
      throw new InternalError('Could not generate job description', {
        error: error.value,
        status: statusCode.value,
      });
    }

    return data.value?.documentId;
  }

  async getGeneratedScreenerQuestions(
    documentId: string,
  ): Promise<GeneratedScreenerQuestionsDocument | null> {
    const path = `/gpt-helper/get-temporary-screening-questions/${documentId}`;
    const { data, error, statusCode } = await useAtsJobApi(path)
      .get()
      .json<GeneratedScreenerQuestionsDocument>();

    if (error.value) {
      throw new InternalError('Could not fetch generated screener questions', {
        error: error.value,
        status: statusCode.value,
        data: { documentId },
      });
    }

    return data.value;
  }

  async updateKnowledgeDisciplines(jobId: number, knowledgeDisciplines: number[]): Promise<void> {
    const path = `/job/${jobId}/taxonomy-knowledge-discipline`;

    const { error, statusCode } = await useFFRestApi(path).put(
      JSON.stringify(knowledgeDisciplines),
    );

    if (error.value) {
      throw new InternalError('Could not update knowledge disciplines', {
        error: error.value,
        status: statusCode.value,
        data: { jobId, knowledgeDisciplines },
      });
    }
  }

  async updateMachineBrands(
    jobId: number,
    machineBrands: { [key: number]: number[] },
  ): Promise<void> {
    const path = `/job/${jobId}/taxonomy-machine`;

    const { error, statusCode } = await useFFRestApi(path).put(JSON.stringify(machineBrands));

    if (error.value) {
      throw new InternalError('Could not update machine brands', {
        error: error.value,
        status: statusCode.value,
        data: { jobId, machineBrands },
      });
    }
  }

  async updateScreenerQuestions(jobId: number, questions: string[]): Promise<void> {
    const path = `/job/${jobId}/screening-questions`;

    const { error, statusCode } = await useFFRestApi(path).put(JSON.stringify(questions));

    if (error.value) {
      throw new InternalError('Could not update screening questions', {
        error: error.value,
        status: statusCode.value,
        data: { jobId, questions },
      });
    }
  }

  async updateJob(
    jobId: number,
    options: {
      knowledgeDisciplines?: number[];
      machineBrands?: { [key: number]: number[] };
      candidateScreening?: {
        employerCareerSiteUrl?: string | null;
        screeningAction?: ScreeningAction | null;
        screeningType?: ScreeningType | null;
      };
      screenerQuestions?: string[];
      jobDetails?: {
        jobTitleId: number | null;
        street1: string | null;
        city: string | null;
        state: string | null;
        postalCode: string | null;
        displayTitle: string | null;
        hireType: HireType | null;
        payRateMin: number | null;
        payRateMax: number | null;
        payRateSalaryMin: number | null;
        payRateSalaryMax: number | null;
        payRatePeriod: PayRatePeriod | null;
        shift: number | null;
        startTime: string | null;
        endTime: string | null;
        anonymous: boolean | null;
        description: string | null;
        rawDescription: string | null;
        knowledgeDisciplines: string[];
        machinesBrands: { title: string; brands: string[] }[];
      };
    },
  ): Promise<void> {
    // Update knowledge disciplines
    if (options.knowledgeDisciplines) {
      await this.updateKnowledgeDisciplines(jobId, options.knowledgeDisciplines);
    }

    // Update machine brands
    if (options.machineBrands) {
      await this.updateMachineBrands(jobId, options.machineBrands);
    }

    // Update screening questions
    if (options.screenerQuestions) {
      const onlyFilledQuestions =
        options.screenerQuestions?.filter((question) => question.trim()) ?? [];
      await this.updateScreenerQuestions(jobId, onlyFilledQuestions);
    }

    const jobObject: {
      jobTitleId?: number | null;
      employerCareerSiteUrl?: string | null;
      screeningAction?: ScreeningAction | null;
      screeningType?: ScreeningType;
      street1?: string;
      city?: string;
      state?: string;
      postalCode?: string;
      displayTitle?: string;
      hireType?: HireType | '' | null;
      payRateMin?: number;
      payRateMax?: number;
      payRateSalaryMin?: number;
      payRateSalaryMax?: number;
      payRatePeriod?: string;
      shift?: number | null;
      startTime?: string;
      endTime?: string;
      anonymous?: boolean;
      rawDescription?: string;
    } = {};

    // Handle candidate screening
    if (options.candidateScreening) {
      if (
        options.candidateScreening.screeningAction === ScreeningAction.URL &&
        options.candidateScreening.employerCareerSiteUrl
      ) {
        jobObject.employerCareerSiteUrl = options.candidateScreening.employerCareerSiteUrl;
        jobObject.screeningAction = options.candidateScreening.screeningAction;
      }

      if (options.candidateScreening.screeningAction === ScreeningAction.SCREEN) {
        jobObject.employerCareerSiteUrl = null;
        jobObject.screeningAction = options.candidateScreening.screeningAction;
      }
      jobObject.screeningType = options.candidateScreening.screeningType ?? ScreeningType.STATIC;
    } else {
      jobObject.employerCareerSiteUrl = null;
      jobObject.screeningAction = null;
    }

    // Handle job details
    if (options.jobDetails) {
      jobObject.jobTitleId = options.jobDetails.jobTitleId ?? null;
      jobObject.street1 = options.jobDetails.street1 ?? '';
      jobObject.city = options.jobDetails.city ?? '';
      jobObject.state = options.jobDetails.state ?? '';
      jobObject.postalCode = options.jobDetails.postalCode ?? '';
      jobObject.displayTitle = options.jobDetails.displayTitle ?? '';
      jobObject.hireType = options.jobDetails.hireType ?? null;
      jobObject.payRatePeriod = options.jobDetails.payRatePeriod ?? PayRatePeriod.HOURLY;
      jobObject.anonymous = options.jobDetails.anonymous ?? false;
      jobObject.rawDescription = options.jobDetails.rawDescription ?? '';

      if (jobObject.payRatePeriod === PayRatePeriod.HOURLY) {
        jobObject.payRateMin = Number(options.jobDetails.payRateMin) ?? 0;
        jobObject.payRateMax = Number(options.jobDetails.payRateMax) ?? 0;
      } else {
        jobObject.payRateSalaryMin = Number(options.jobDetails.payRateSalaryMin) ?? 0;
        jobObject.payRateSalaryMax = Number(options.jobDetails.payRateSalaryMax) ?? 0;
      }

      if ([0, 1, 2, 3, null].includes(options.jobDetails.shift)) {
        jobObject.shift = options.jobDetails.shift;
      }

      if (options.jobDetails.shift === 0) {
        jobObject.shift = 0;
        jobObject.startTime = options.jobDetails.startTime ?? '';
        jobObject.endTime = options.jobDetails.endTime ?? '';
      }

      // Handle Description
      const generatedDescription = getJobSkillsTextForJobDescription({
        knowledgeDisciplines: options.jobDetails.knowledgeDisciplines,
        machinesBrands: options.jobDetails.machinesBrands,
      });
      jobObject.rawDescription += generatedDescription;
      jobObject.rawDescription = formatJobDescription(jobObject.rawDescription);
    }

    // Try to update the job
    const path = `/job/${jobId}`;
    const { error, statusCode } = await useFFRestApi(path).patch(JSON.stringify(jobObject));

    if (error.value) {
      throw new InternalError('Could not update job', {
        error: error.value,
        status: statusCode.value,
        data: { jobId, jobObject },
      });
    }
  }
}
